import { useCallback } from 'react';

import { getSession } from 'config/amplifyConfig';
import { Socket } from 'socket.io-client';

/**
 * `useSocketConnection` is a custom hook that manages a Socket.IO connection with authentication.
 * It provides two functions `socketInit` and `socketClose` to manage the connection.
 *
 * @param {Socket} socket - The Socket.IO client instance.
 *
 * @returns {Object} The return object contains two methods.
 * @returns {Function} socketInit - This function initializes the socket connection with optional auth refresh.
 *                                  It fetches the auth session (using cached tokens by default), extracts
 *                                  the access token and id token, sets them in the socket auth object, and
 *                                  then connects the socket.
 * @returns {Function} socketClose - This function disconnects the socket connection and clears the
 *                                   authorization from the socket auth object.
 *
 * @example
 * const { socketInit, socketClose } = useSocketConnection({ socket });
 * // Use cached tokens by default
 * socketInit();
 * // Force refresh tokens only when needed (e.g., after an auth error)
 * socketInit(true);
 */
export const useSocketConnection = ({ socket }: { socket: Socket }) => {
  /**
   * Initialize the socket connection with authentication
   * @param forceRefresh - Whether to force refresh the authentication tokens
   *                       Only set to true when handling auth errors or when tokens might be invalid
   */
  const socketInit = useCallback(
    async (forceRefresh = false) => {
      const session = await getSession({ forceRefresh }).catch((error: unknown) => {
        console.error('Failed to get authentication session:', error);
        return null;
      });

      if (!session) return;

      const accessToken = session?.tokens?.accessToken?.toString();
      const idToken = session?.tokens?.idToken?.toString();

      if (accessToken && idToken) {
        socket.auth = {
          authorization: `Bearer ${accessToken}`,
          idToken
        };
        socket.connect();
      }
    },
    [socket]
  );

  const socketClose = useCallback(() => {
    socket.auth = { authorization: '' };
    socket.disconnect();
    console.info('close socket connection');
  }, [socket]);

  return { socketInit, socketClose };
};
