import { Common } from '@thecvlb/design-system';
import { notifySuccess } from 'components/common/Toast/Toast';
import InputField from 'components/forms/controlled/InputField';
import { useAppDispatch } from 'hooks/redux';
import { useForm } from 'react-hook-form';
import { useCreateChannelMutation } from 'store/channels/channelsSlice';
import { closeModal } from 'store/modal/modalSlice';

type FormValues = {
  channelTitle: string;
};

const CreateNewChannel: React.FC<{
  patientId: string;
}> = ({ patientId }) => {
  const [createChannel, { isLoading }] = useCreateChannelMutation();

  const dispatch = useAppDispatch();
  const { control, handleSubmit, formState, watch } = useForm<FormValues>({
    defaultValues: { channelTitle: '' },
    mode: 'onChange'
  });

  const { errors, isDirty } = formState;
  const channelTitle = watch('channelTitle');
  const isDisabled =
    isLoading || !!errors.channelTitle || !isDirty || channelTitle.trim().length === 0;

  const onSubmit = (formData: FormValues) => {
    createChannel({
      patientId,
      channelTitle: formData.channelTitle.trim()
    })
      .unwrap()
      .then(() => {
        notifySuccess(`Channel has been successfully created`);
        dispatch(closeModal());
      });
  };

  const buttonClassName = 'justify-center !w-full';

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    const isEnter = event.key === 'Enter';

    if (isEnter && !event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
      event.preventDefault();
      if (!isDisabled) {
        handleSubmit(onSubmit)();
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="new_channel_popup"
      className="flex flex-col gap-6 p-6"
      onKeyDown={handleKeyDown}
    >
      <p data-testid="heading" className="text-xl font-bold">
        Add new channel
      </p>
      <InputField
        dataTestId="channel_name_field"
        name="channelTitle"
        type="text"
        placeholder="Enter channel title"
        control={control}
        size="md"
        rules={{
          required: 'Channel title is required',
          validate: (value: string) =>
            !/[*@!?#%$&()^~{}]+/.test(value) || "Channel title can't contain special characters"
        }}
      />
      <div className={'flex gap-2'}>
        <Common.Button
          onClick={() => dispatch(closeModal())}
          color="white-alt"
          size="sm"
          className={buttonClassName}
        >
          Cancel
        </Common.Button>
        <Common.Button
          disabled={isDisabled}
          dataTestId="save_btn"
          color="blue"
          size="sm"
          className={buttonClassName}
          type="submit"
        >
          Create channel
        </Common.Button>
      </div>
    </form>
  );
};

export default CreateNewChannel;
