import React, { useMemo } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { ProfileImageProps } from '@thecvlb/design-system/lib/src/common';
import SteadyMDLogo from 'assets/icons/steadyMD/steadyMDLogo.webp';
import { notifySuccess } from 'components/common/Toast/Toast';
import TransferPopup from 'components/common/TransferPopup';
import { TransferPopupItemProps } from 'components/common/TransferPopup/transferPopup.types';
import User from 'components/common/User';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/redux';
import type { AssignedToInfo } from 'models/tasks.types';
import { useUpdateTaskAssignMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

import { generateAudienceIcon, generateAudienceTitle, getAudience } from './dateCell.settings';

const AssignedToCell: React.FC<Partial<AssignedToInfo>> = ({
  _id,
  profileImage,
  userRoleInfo,
  name,
  specificAudience = [],
  defaultAudience = [],
  category,
  taskId,
  tags,
  escalationDetails,
  teams,
  patientId,
  assignedAt,
  assignedTeamAt,
  steadymd
}) => {
  const { _id: userId } = useAppSelector(selectUser);
  const [updateTaskAssign] = useUpdateTaskAssignMutation();
  const audience = getAudience(specificAudience, defaultAudience);

  const reassignTaskAudience = (item: TransferPopupItemProps) => {
    updateTaskAssign({ taskId: taskId ?? '', audience: [item.shortCode] })
      .unwrap()
      .then((data) => notifySuccess(data.message));
  };

  const showAssignedToProvider = _id && name;
  const showEscalatedTo = !showAssignedToProvider && escalationDetails?.status === 'escalated';
  const showAssignedAudience =
    !showAssignedToProvider && !showEscalatedTo && audience.length > 0 && !teams;
  const showUnassigned =
    !showAssignedToProvider && !showEscalatedTo && !showAssignedAudience && !teams;

  const escalatedIcon = (
    <div
      title="Escalate to"
      className="flex size-5 min-w-[20px] items-center justify-center rounded-full bg-yellow-500"
    >
      <Common.Icon name="error" className="size-4 text-white" />
    </div>
  );

  const assignedIcon = (
    <div
      title="Escalate to"
      className="relative flex size-5 min-w-[20px] items-center justify-center rounded-full bg-yellow-500"
    >
      <Common.Icon name="team" className="size-5 text-white" />
      <span className="absolute -right-0.5 bottom-0 rounded-full bg-gray-500 px-[2.5px] pb-[2px] pt-px text-3xs font-bold text-white ">
        T
      </span>
    </div>
  );

  const escalatedTo = !!escalationDetails?.teams?.length
    ? escalationDetails?.teams?.map((team) => team.name).join(', ')
    : escalationDetails?.user
      ? escalationDetails?.user.name
      : '';
  const teamTo = !!teams?.length ? teams.map((team) => team.name).join(', ') : '';

  const showTeam = useMemo(() => {
    if (!teamTo) return false;
    if (teamTo && !escalationDetails && !showAssignedToProvider) return true;

    const datesToCompare = [];
    if (assignedAt) datesToCompare.push(assignedAt);
    if (escalationDetails?.escalatedAt) datesToCompare.push(escalationDetails?.escalatedAt);

    return (
      assignedTeamAt && datesToCompare.every((date) => dayjs(assignedTeamAt).isAfter(dayjs(date)))
    );
  }, [
    teamTo,
    showEscalatedTo,
    assignedAt,
    assignedTeamAt,
    escalationDetails,
    showAssignedToProvider
  ]);

  const showSteadyMD = showTeam && teams?.find((team) => team.tags?.includes('steadymd'));

  return (
    <div className="flex items-center font-normal">
      <TransferPopup
        src={profileImage}
        name={name ?? ''}
        role={userRoleInfo?.shortName}
        onButtonClick={reassignTaskAudience}
        isTasksList
        assignedToId={_id}
        taskId={taskId}
        tags={tags}
        taskCategory={category}
        specificAudience={audience}
        patientId={patientId}
        steadymd={steadymd}
      >
        {showSteadyMD && (
          <div className="flex items-center justify-center gap-1">
            <img src={SteadyMDLogo} alt="SteadyMD Logo" className="size-5 rounded-full" />
            <span data-testid="team_name" className="text-sm font-semibold">
              SteadyMD
            </span>
          </div>
        )}

        {!!showAssignedToProvider && !showSteadyMD && (
          <>
            {!!escalationDetails && escalatedIcon}
            <User
              src={profileImage}
              role={userRoleInfo?.shortName as ProfileImageProps['role']}
              name={_id === userId ? 'Me' : name}
            />
          </>
        )}
        {showTeam && !showSteadyMD && (
          <div className="flex items-center justify-center gap-1">
            {assignedIcon}
            <span data-testid="team_name" className="text-sm font-semibold">
              {teamTo}
            </span>
          </div>
        )}
        {showEscalatedTo && !showTeam && (
          <div className="flex items-center justify-center gap-1">
            {escalatedIcon}
            <span data-testid="team_name" className="text-sm font-semibold">
              {escalatedTo}
            </span>
          </div>
        )}
        {showAssignedAudience && (
          <div className="flex items-center">
            {generateAudienceIcon(audience)}
            <div data-testid="user_name" className="ml-1 text-sm font-medium text-gray-700">
              {generateAudienceTitle(audience)}
            </div>
          </div>
        )}
        {showUnassigned && <span className="text-sm font-normal">Unassigned</span>}
      </TransferPopup>
    </div>
  );
};

export default AssignedToCell;
