import { Amplify } from 'aws-amplify';
import { ResourcesConfig } from 'aws-amplify';
import { AuthSession, fetchAuthSession } from 'aws-amplify/auth';
import { getAuthConfig } from 'config/auth';

// Module-level variable to track if Amplify has been configured
let isAmplifyConfigured = false;

export const IS_LIVE = import.meta.env.VITE_APP_TITLE === 'production';

/**
 * Initialize Amplify configuration.
 * This function ensures Amplify is only configured once, regardless of how many times it's called.
 *
 * @param authConfig The authentication configuration for Amplify
 */
export const initialize = () => {
  // use 'http://localhost:9001/login' for local development
  const redirectUrl = IS_LIVE
    ? `${location.protocol}//${location.host}`
    : `${location.protocol}//${location.host}/login`;

  const authConfig: ResourcesConfig = getAuthConfig({
    redirectSignIn: [redirectUrl],
    redirectSignOut: [redirectUrl]
  });
  if (isAmplifyConfigured) {
    return;
  }

  Amplify.configure(authConfig);
  isAmplifyConfigured = true;
  console.info('Amplify configuration initialized');
};

/**
 * Get the current authentication session.
 *
 * This function leverages AWS Amplify's built-in session caching mechanism to minimize
 * unnecessary calls to Cognito endpoints. By default, Amplify caches the auth session
 * and only refreshes when needed.
 *
 * The forceRefresh parameter should only be used when you explicitly need a fresh token,
 * such as when handling authentication errors or when previous tokens might be invalid.
 *
 * @param options Configuration options
 * @param options.forceRefresh Whether to force a refresh of the session (defaults to false)
 * @returns The auth session
 */
export const getSession = async (options?: { forceRefresh?: boolean }): Promise<AuthSession> => {
  // By default, Amplify's fetchAuthSession already implements caching
  // Only pass forceRefresh:true when absolutely necessary
  return fetchAuthSession({
    forceRefresh: options?.forceRefresh === true
  });
};
