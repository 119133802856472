import { Common } from '@thecvlb/design-system/lib/src';
import useZoomCall from 'hooks/common/useZoomCall';
import useOpenTask from 'hooks/tasks/useOpenTask/useOpenTask';

import { AppointmentProps } from './appointment.types';

const Appointment: React.FC<AppointmentProps> = ({ appointment, queueTime }) => {
  const { handleOpenTask } = useOpenTask();
  const { taskInfo, isPiP: isZoomPiP, isZoomCallOpen, toggleIsPiP } = useZoomCall();
  const startTime = queueTime || appointment?.appointmentTime?.startTime;
  const text = appointment?.appointmentType.displayName;
  const isMessage = appointment.appointmentMethod === 'message';
  const onClick = () => {
    const shouldExpandVideoCall =
      isZoomCallOpen && isZoomPiP && taskInfo?._id === appointment?.taskId;
    if (shouldExpandVideoCall) {
      toggleIsPiP(false);
    }
    handleOpenTask({
      taskId: appointment.taskId,
      assignToId: appointment.doctorId,
      assignToName: appointment.staffName
    });
  };

  return (
    <div className="rounded-2xl shadow">
      <Common.Appointment
        onClick={onClick}
        title={text}
        provider={appointment?.staffName}
        {...(isMessage ? { type: 'message' } : startTime && { startTime })}
      />
    </div>
  );
};

export default Appointment;
