import { ErrorInfo } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import packageInfo from '../../package.json';

const initDatadog = () => {
  if (
    import.meta.env.VITE_APP_TITLE === 'production' ||
    import.meta.env.VITE_APP_TITLE === 'development'
  ) {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATA_DOG_APP_ID,
      clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: import.meta.env.VITE_DATA_DOG_SERVICE,
      env: `env:${import.meta.env.VITE_APP_TITLE}`,
      version: packageInfo.version,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [import.meta.env.VITE_API_URL],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true
    });
  }

  if (
    import.meta.env.VITE_APP_TITLE === 'production' ||
    import.meta.env.VITE_APP_TITLE === 'development'
  ) {
    datadogLogs.init({
      clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: import.meta.env.VITE_DATA_DOG_SERVICE,
      env: `env:${import.meta.env.VITE_APP_TITLE}`,
      version: packageInfo.version,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error']
    });
  }
};

const logError = (error: Error, info: ErrorInfo) => {
  datadogLogs.logger.error(error.message, info);
};

export { initDatadog, logError };
