import React from 'react';

import classNames from 'classnames';

import HoursCell from '../HoursCell';

const UserTimeSkeleton: React.FC = () => {
  return (
    <>
      {Array.from({ length: 8 }).map((_, idx) => (
        <div key={idx} className="flex w-full animate-pulse" data-testid="user-time-skeleton">
          <div className="flex w-44 flex-none items-center py-1 pl-6 pr-3">
            <div className="mr-1 size-5 rounded-full bg-slate-200" />
            <div className="h-3 w-20 rounded-2xl bg-slate-200" />
          </div>
          <div className="relative flex h-[35px] w-full">
            <HoursCell showTopBorder />
            <div
              className={classNames('absolute inset-0 my-auto h-6 rounded-md bg-slate-200', {
                'left-[5%] right-[20%]': idx === 3 || idx === 7,
                'left-[15%] right-[30%]': idx === 1 || idx === 5,
                'left-[5%] right-[50%]': idx === 2 || idx === 6
              })}
            />
            {(idx === 2 || idx === 6) && (
              <div className="absolute inset-0 left-[60%] right-[5%] my-auto h-6 rounded-md bg-slate-200" />
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default UserTimeSkeleton;
