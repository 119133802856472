import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { ErrorMessage } from '@hookform/error-message';
import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import Alert from 'components/common/Alert';
import DatePickerInput from 'components/common/DatePickerInput';
import SearchCombobox from 'components/common/form/SearchCombobox/SearchCombobox';
import TimeInput from 'components/common/form/timeInput';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import ControlledMultiSelect from 'components/forms/controlled/ControlledMultiSelect';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import InputField from 'components/forms/controlled/InputField';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { DateFormat } from 'enums/dateFormats';
import { MedicationBrandNames } from 'enums/medications';
import { PatientPrescriptionsType } from 'enums/prescriptions';
import { RoleShortName } from 'enums/role';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FieldError, FormProvider, useController, useForm, useWatch } from 'react-hook-form';
import { useToggle } from 'react-use';
import { closeModal } from 'store/modal/modalSlice';
import { useLazyGetPatientPrescriptionsQuery } from 'store/prescriptions/prescriptionsSlice';
import { useCreateTaskStaffNoteMutation } from 'store/staffNotes/staffNotesSlice';
import {
  AvailableStaffResponse,
  CreateNewTaskParams,
  CreateRequestOrderTaskParams,
  RequestOrderOptionsProps,
  RequestPAProps
} from 'store/tasks/task.types';
import {
  selectTask,
  useCreateNewTaskMutation,
  useCreateRequestOrderTaskMutation,
  useGetTaskRequestOrderReasonsQuery,
  useRequestPAMutation
} from 'store/tasks/tasksSlice';
import { TeamTags } from 'store/teams/teams.types';
import { useGetTeamsQuery } from 'store/teams/teamsSlice';
import { selectUser } from 'store/user/userSlice';
import { extractAndFormatPrescriptionName, validation } from 'utils/helpers';
import { getGroupedReasons } from 'utils/reasons';

import { assignToOptions, TARGET_TEAM, useGetAvailableStaff } from './createTaskForm.settings';
import {
  CreateTaskFormProps,
  CreateTaskFormState,
  FormatPrescriptionLabelProps,
  OrderForFollowUpOption,
  TaskCategory
} from './createTaskForm.types';
import TaskUpdated from './TaskUpdated';
import ControlledCombobox from '../../forms/controlled/ControlledCombobox';
import { handleRequired } from '../AppointmentTypes/appointmentType.settings';

dayjs.extend(LocalizedFormat);

const selectCreateTaskFormState = createSelector([selectTask, selectUser], (task, user) => ({
  taskDetails: task.taskDetails,
  userId: user._id,
  userRoleInfo: user.userRoleInfo
}));

const CreateTaskForm = ({ patient }: CreateTaskFormProps) => {
  const dispatch = useAppDispatch();

  const { data: teamsList } = useGetTeamsQuery({});
  const { data: taskRequestOrderReasonsList } = useGetTaskRequestOrderReasonsQuery();

  const requestOrderReasonsGroupOption = useMemo(
    () => getGroupedReasons(taskRequestOrderReasonsList || []),
    [taskRequestOrderReasonsList]
  );

  const assignToTeamOption = teamsList?.teams?.find(
    (team) =>
      team?.tags?.includes(TeamTags.REQUEST_ORDER) ||
      team.name.toLowerCase() === TARGET_TEAM.toLowerCase()
  ) || {
    _id: '',
    name: ''
  };

  const targetTeamOption = {
    label: assignToTeamOption?.name,
    value: assignToTeamOption?._id
  };

  const { taskDetails, userId, userRoleInfo } = useAppSelector(selectCreateTaskFormState);
  const [requestPA, { isLoading: isLoadingRequestPA, isSuccess: requestPACreated }] =
    useRequestPAMutation();
  const [createNewTask, { isLoading, isSuccess: taskCreated }] = useCreateNewTaskMutation();
  const [createOrderRequest] = useCreateRequestOrderTaskMutation();
  const isTaskCreated = taskCreated || requestPACreated;
  const [sendTaskStaffNote] = useCreateTaskStaffNoteMutation();
  const methods = useForm<CreateTaskFormState>();
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    register,
    formState,
    clearErrors,
    setError
  } = methods;
  const {
    field,
    formState: { errors }
  } = useController({
    control,
    name: 'assignTo',
    rules: {
      required: 'Select assign to option'
    }
  });

  const isStaffMember = watch('assignTo') === 'SSM';
  const formData = useWatch({ control }) as CreateTaskFormState;
  const isRequestPA = formData.taskCategory?.value === TaskCategory.RequestPA;
  const isRequestOrder = formData.taskCategory?.value === TaskCategory.RequestOrder;
  const showPAAssignToWarning =
    isRequestPA && formData.assignTo && formData.assignTo !== RoleShortName.MedicalAssistant;

  const [isDueDate, setIsDueDate] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [staffSearchKey, setStaffSearchKey] = useState('');
  const [includeLeadership, toggleIncludeLeadership] = useToggle(false);
  const [isTaskNotUrgent, toggleIsTaskNotUrgent] = useToggle(false);
  const [dueDate, setDueDate] = useState(dayjs().format(DateFormat.MM_DD_YYYY));
  const [orderForFollowUpOptions, setOrderForFollowUpOptions] = useState<OrderForFollowUpOption[]>(
    []
  );
  const [isEscalateAlertVisible, setIsEscalateAlertVisible] = useToggle(false);

  const selectedTaskCategory = getValues('taskCategory');
  const selectedPatient = getValues('patient');
  const selectedRequestReasons: RequestOrderOptionsProps[] = (getValues('requestReason') ||
    []) as RequestOrderOptionsProps[];

  const disabledStaffField = !selectedPatient?.id || !selectedTaskCategory?.value;
  const showRequestPA = userRoleInfo?.editingPermissions?.includes('CREATE_REQUEST_PA_TASK');
  const showRequestOrderOption =
    userRoleInfo?.editingPermissions?.includes('PRESCRIPTION_EDIT') ||
    userRoleInfo?.editingPermissions?.includes('PRESCRIPTION_ADD');

  const showRequestOrder = selectedTaskCategory?.value === TaskCategory.RequestOrder;

  const taskCategoryOptions = [
    { label: TaskCategory.Request, value: TaskCategory.Request },
    ...(showRequestPA ? [{ label: TaskCategory.RequestPA, value: TaskCategory.RequestPA }] : []),
    ...(showRequestOrderOption
      ? [{ label: TaskCategory.RequestOrder, value: TaskCategory.RequestOrder }]
      : [])
  ];
  const [getPatientPrescription, { data: prescriptionsData }] =
    useLazyGetPatientPrescriptionsQuery();

  const lmdPrescriptions =
    prescriptionsData?.data?.filter(
      (prescription) =>
        prescription.type === PatientPrescriptionsType.LMDPrescription ||
        prescription.type === PatientPrescriptionsType.Order
    ) || [];

  const formatPrescriptionLabel = ({
    name,
    signedAt,
    isTasked
  }: FormatPrescriptionLabelProps): string =>
    `${extractAndFormatPrescriptionName(name)}${signedAt ? ` • ${dayjs(signedAt).format(DateFormat.MM_DD_YY)}` : ''}${isTasked ? ' • Tasked' : signedAt ? '' : ' • Draft'}`;

  useEffect(() => {
    // we trigger the request to get the patient's prescriptions only when the Patient is selected
    // and the Task Category is Request Order because we don't need this data for other Task Categories
    if (selectedPatient?.id && selectedTaskCategory?.value === TaskCategory.RequestOrder) {
      getPatientPrescription({
        patientId: selectedPatient.id,
        params: { limit: 20, sortOrder: 'DESC' }
      });

      const ordersForFollowUp = lmdPrescriptions.map((prescription) => ({
        value: prescription.id,
        label:
          formatPrescriptionLabel({
            name: prescription.name,
            signedAt: prescription.signedAt || null,
            isTasked: !!prescription?.requestOrderTask?.id
          }) || '',
        disabled: !!prescription?.requestOrderTask?.id
      }));

      setOrderForFollowUpOptions(ordersForFollowUp);
    }
  }, [prescriptionsData, selectedPatient?.id, getPatientPrescription, selectedTaskCategory?.value]);

  const staffError = (formData?.staff?.value as AvailableStaffResponse)?.warning
    ? (formData?.staff?.value as AvailableStaffResponse)?.warning
    : undefined;

  const {
    searchData,
    isLoading: isLoadingStaff,
    hasClinicalLeader,
    isSuccess
  } = useGetAvailableStaff(
    {
      taskCategory: selectedTaskCategory?.value || '',
      patientId: selectedPatient?.id || patient?.id,
      searchKey: staffSearchKey,
      isUrgentTask: isUrgent,
      timezone: dayjs.tz.guess()
    },
    includeLeadership,
    isStaffMember
  );

  const noAvailableStaffMembers =
    isSuccess && !isLoading && !!staffSearchKey && !searchData.length
      ? { warning: 'No staff members found' }
      : undefined;

  const patientInfo = useMemo(() => {
    const label = taskDetails?.personalInfo?.firstName
      ? `${taskDetails.personalInfo.firstName} ${taskDetails.personalInfo.lastName}`
      : patient
        ? patient.fullName
        : '';

    const value = taskDetails?.personalInfo?._id || patient?.id || '';
    const id = value;

    return {
      label,
      value,
      id
    };
  }, [patient, taskDetails.personalInfo]);

  const hasDefaultPatient = Object.values(patientInfo).every((value) => Boolean(value));

  const medicationOptions = Object.values(MedicationBrandNames).map((medication) => ({
    value: medication,
    label: medication
  }));

  const handleClearStaff = (clearStaffField?: boolean) => {
    if (clearStaffField) setStaffSearchKey('');
    clearErrors('staff');
    setValue('staff', {
      id: '',
      value: '',
      label: ''
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStaffSearchKey(event?.target.value);
    if (!event.target.value?.length) {
      handleClearStaff(true);
    }
  };

  const handleChangeStaff = (option: Option) => {
    clearErrors('staff');
    setValue('staff', option);

    const warningMessage = (option.value as AvailableStaffResponse)?.warning?.message;

    if (warningMessage) {
      setError(`staff`, { type: 'manual', message: warningMessage });
    }
    toggleIsTaskNotUrgent(false);
  };

  const handleChangeIsUrgentStatus = (checked: boolean) => {
    setIsUrgent(checked);
    toggleIsTaskNotUrgent(false);
    setValue('isUrgent', checked);

    /**
     * If the selected staff has an error we remove it from the form (Requirement of the task)
lo     */
    if (staffError?.message) {
      handleClearStaff(true);
      setError('staff', {
        type: 'manual',
        message:
          'Please choose a different provider who is currently available in order create an urgent task.'
      });
    }
  };

  const handleCancel = () => dispatch(closeModal());

  const onSubmit = (data: CreateTaskFormState) => {
    const reqPAData: RequestPAProps = {
      patientUserId: data.patient.id,
      isUrgent: data.isUrgent,
      note: data.medication?.value || '',
      medication: data.medication?.value,
      timezone: dayjs.tz.guess()
    };
    const reqData: CreateNewTaskParams = {
      patientId: data.patient.id,
      note: data.taskDetails,
      ...(isDueDate && {
        dueDate: dayjs(data.dueDate.date + ' ' + data.dueDate.time).toISOString()
      }),
      isUrgent: data.isUrgent,
      ...(Boolean(taskDetails._id) && { parentTaskId: taskDetails._id }),
      timezone: dayjs.tz.guess()
    };

    const reqOrderData: CreateRequestOrderTaskParams = {
      patientId: data.patient.id,
      note: data.taskDetails,
      reasons: (data?.requestReason && data.requestReason.map((reason) => reason.value)) || [],
      teams: [{ id: assignToTeamOption._id }],
      elationPrescriptionId: Number(data.orderForFollowUp?.value),
      ...(isDueDate && {
        dueDate: dayjs(data.dueDate.date + ' ' + data.dueDate.time).toISOString()
      }),
      ...(Boolean(taskDetails._id) && { parentTaskId: taskDetails._id })
    };

    const isRequestOrderTeam = data?.assignTo?.toLowerCase() === TARGET_TEAM.toLowerCase();

    if (data?.assignTo?.toLowerCase() === 'myself') {
      (isRequestPA ? reqPAData : reqData).assignedTo = userId;
    } else if (data?.assignTo?.toLowerCase() === 'ssm' && data?.staff?.id) {
      (isRequestPA ? reqPAData : reqData).assignedTo = data.staff.id;
    } else if (!isRequestOrderTeam) {
      (isRequestPA ? reqPAData : reqData).audience = data.assignTo;
    }

    (isRequestPA
      ? requestPA({ ...reqPAData })
      : isRequestOrder
        ? createOrderRequest({ body: reqOrderData })
        : createNewTask({ body: reqData })
    )
      .unwrap()
      .then((res) => {
        // Send staff note if it is a request task
        if (!isRequestOrder && !isRequestPA && data.taskDetails && res?.data?.id) {
          sendTaskStaffNote({
            taskId: res.data.id,
            body: { note: data.taskDetails, isUrgent: data.isUrgent, staffNoteFile: null }
          });
        }

        // NOTE: if the Expedite reasons are selected, created Request Order
        // task will be escalated on the BE side.

        notifySuccess('Task created successfully');
        handleCancel();
      });
  };

  useEffect(() => {
    setValue('taskCategory', taskCategoryOptions[0]);
  }, [setValue]);

  useEffect(() => {
    const escalateReason =
      selectedRequestReasons.length && selectedRequestReasons.find((reason) => reason.toEscalate);

    setIsEscalateAlertVisible(!!escalateReason);
  }, [selectedRequestReasons]);

  useEffect(() => {
    if (showRequestOrder) {
      setValue('assignTo', targetTeamOption.label);
    } else {
      setValue('assignTo', assignToOptions[2].value); // Sets back to 'MA'
    }
  }, [showRequestOrder, targetTeamOption.label, setValue, assignToOptions]);

  useEffect(() => {
    setValue('dueDate', {
      time: getValues('dueDate.time'),
      date: dayjs(dueDate.toString(), DateFormat.MM_DD_YYYY).format(DateFormat.MM_DD_YYYY)
    });
  }, [dueDate, setValue, getValues]);

  useEffect(() => {
    handleClearStaff(true);
    handleChangeIsUrgentStatus(false);
    setIsEscalateAlertVisible(false);
  }, [selectedTaskCategory, selectedPatient]);

  // Clear errors when task category changes to prevent unexpected disabling of the submit button
  // when the user changes the task category and have some errors on the prefious category
  useEffect(() => {
    clearErrors();
  }, [selectedTaskCategory]);

  // Check if any required fields has errors
  const disabledSubmit =
    isLoading ||
    isLoadingRequestPA ||
    (!!staffError?.shouldConfirm && !isTaskNotUrgent) ||
    staffError?.canAssignStaff === false;

  const staffSuccessHelperText =
    !!formData?.staff?.value && !staffError?.message
      ? "Provider is online and licensed in patient's state"
      : '';

  const staffHelperText =
    staffError?.message || noAvailableStaffMembers?.warning || staffSuccessHelperText;

  const handleInputValueChange = (value: string) => {
    setDueDate(value);

    if (!value.trim()) {
      // Clear the date when input is empty
      setValue('dueDate.date', '', {
        shouldValidate: true,
        shouldDirty: true
      });

      setError('dueDate.date', { message: 'Due date is required' });
    } else {
      clearErrors('dueDate.date');
    }
  };

  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="create_task_form" className="p-6">
        <h2 data-testid="header" className="mb-6 text-xl font-bold text-gray-700">
          Create new task
        </h2>
        {!isTaskCreated && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              <ControlledSelect
                dataTestId="task_category"
                control={control}
                options={taskCategoryOptions}
                name="taskCategory"
                className="w-full"
                label="Task category"
                rules={validation('Task category')}
                errors={formState.errors.taskCategory}
              />

              <SearchCombobox
                control={control}
                setValue={setValue}
                name="patient"
                label="Patient"
                userType="Patient"
                placeholder="Search for a patient"
                defaultValue={hasDefaultPatient ? patientInfo : ''}
                isRequired
                size="sm"
                preIcon="search"
                labelDirection="col"
              />

              {showRequestOrder && (
                <>
                  <ControlledSelect
                    control={control}
                    dataTestId="order_for_follow_up"
                    options={orderForFollowUpOptions}
                    name="orderForFollowUp"
                    className="w-full"
                    label="Order for follow-up"
                    rules={validation('Order for follow-up')}
                    errors={formState.errors.orderForFollowUp}
                    placeholder={
                      orderForFollowUpOptions.length === 0
                        ? 'Patient have no orders for follow-up'
                        : 'Select tags'
                    }
                    disabled={orderForFollowUpOptions.length === 0}
                  />
                  <ControlledMultiSelect
                    dataTestId="request_reason"
                    control={control}
                    labelDirection="col"
                    options={requestOrderReasonsGroupOption}
                    placeholder="Enter input..."
                    label="Request reason"
                    name="requestReason"
                    isSuccess={isSuccess}
                    rules={validation('Request reason')}
                    isGrouped
                  />
                  <InputField
                    dataTestId="assign_to"
                    name={'assignTo'}
                    label="Assign to"
                    control={control}
                    labelDirection="col"
                    defaultValue={targetTeamOption.label}
                    placeholder="Assign to"
                    preIcon="team"
                    disabled
                  />
                </>
              )}

              {!showRequestOrder && (
                <Common.SelectAlt
                  dataTestId="assign_to_dropdown"
                  label="Assign to"
                  size="sm"
                  name={field.name}
                  value={field.value || ''}
                  options={assignToOptions}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  hideSuccessState
                  error={errors[field.name] as FieldError}
                />
              )}

              {showPAAssignToWarning && (
                <Alert
                  type="notice"
                  containerClasses="bg-yellow-100 shadow-none"
                  children={
                    <p className="ml-1 text-base font-medium">
                      New ‘Request PA’ tasks are usually assigned to MAs
                    </p>
                  }
                />
              )}

              {isStaffMember && (
                <>
                  {hasClinicalLeader && (
                    <div>
                      <Common.Checkbox
                        checked={includeLeadership}
                        onChange={toggleIncludeLeadership}
                        color="blue"
                      >
                        Include clinical leadership - please only do this when other providers are
                        unavailable
                      </Common.Checkbox>
                    </div>
                  )}
                  <ControlledCombobox
                    size="sm"
                    control={control}
                    name="staff"
                    label="Staff member"
                    placeholder="Search for staff"
                    labelDirection="col"
                    preIcon="search"
                    inputValue={staffSearchKey}
                    onChange={handleChangeStaff}
                    onInputChange={handleInputChange}
                    options={searchData}
                    isLoading={isLoadingStaff}
                    disabled={disabledStaffField}
                    hideComboboxButton
                    errors={staffError || noAvailableStaffMembers}
                    helperText={staffHelperText}
                    immediate
                    showAllOptions
                    rules={{
                      validate: {
                        required: (value) => handleRequired(value, 'Please select a staff member')
                      }
                    }}
                  />
                  {disabledStaffField && (
                    <div className="-mt-3 text-sm text-gray-500">
                      Please choose a patient to assign a staff member.
                    </div>
                  )}
                  {staffError?.shouldConfirm && (
                    <div>
                      <Common.Checkbox
                        checked={isTaskNotUrgent}
                        onChange={toggleIsTaskNotUrgent}
                        color="blue"
                      >
                        I confirm that the task is not urgent so it can wait more than 48 hours
                      </Common.Checkbox>
                    </div>
                  )}
                </>
              )}
              {isRequestPA && (
                <ControlledSelect
                  control={control}
                  labelDirection="col"
                  options={medicationOptions}
                  rules={validation('Medication')}
                  placeholder="Select..."
                  label="Medication"
                  name="medication"
                />
              )}
              {!isRequestPA && (
                <Common.TextArea
                  dataTestId="task_details_field"
                  label="Task details"
                  size="sm"
                  placeholder="What do you need completed?"
                  {...register('taskDetails', {
                    required: { value: true, message: 'Please fill out this field' }
                  })}
                  helper={formState.errors.taskDetails ? 'Please fill out this field' : ''}
                  errors={formState.errors.taskDetails}
                />
              )}
              {!isRequestPA && (
                <Common.Checkbox
                  dataTestId="add_due_date_checkbox"
                  value="dueDate"
                  size="sm"
                  color="blue"
                  onChange={(event) => {
                    setIsDueDate(event.target.checked);
                  }}
                  checked={isDueDate}
                >
                  Add a due date
                </Common.Checkbox>
              )}
              {!isRequestPA && isDueDate && (
                <div className="flex flex-col">
                  <div className="flex items-center gap-4">
                    <DatePickerInput
                      dataTestId="due_date_scope"
                      wrapperClasses="w-11/12"
                      label="Due"
                      inputValue={dueDate}
                      setInputValue={handleInputValueChange}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      inputClassName="!text-gray-500"
                      size="sm"
                      placeholder={DateFormat.MM_DD_YYYY}
                      errors={formState?.errors?.dueDate?.date}
                    />
                    <TimeInput className="h-[33px] w-10/12 rounded-md" />
                  </div>
                  <ErrorMessage
                    errors={formState?.errors}
                    name="dueDate.date"
                    render={({ message }) => (
                      <p className="mt-2 text-xs text-red-500" data-testid="ends-date-error">
                        {message}
                      </p>
                    )}
                  />
                </div>
              )}
              {!showRequestOrder && (
                <Common.Checkbox
                  dataTestId="mark_as_urgent_checkbox"
                  size="sm"
                  value="isUrgent"
                  color="blue"
                  onChange={(event) => handleChangeIsUrgentStatus(event.target.checked)}
                  checked={isUrgent}
                >
                  Mark as urgent
                </Common.Checkbox>
              )}
              {isUrgent && (
                <Alert type="error">
                  <span className="text-base">
                    <span className="font-bold">Important: </span>Please only mark a task as urgent
                    if it requires urgent clinical attention.
                  </span>
                </Alert>
              )}
              {isEscalateAlertVisible && (
                <Alert type="error">
                  <span className="text-base">
                    <span className="font-bold">Important: </span>
                    The reason you selected will be handled as an escalated task
                  </span>
                </Alert>
              )}
              <div className="flex gap-2">
                <button
                  data-testid="cancel_btn"
                  className="w-full rounded-lg bg-gray-100 py-[7.5px] text-sm font-bold text-gray-700 disabled:bg-gray-200 disabled:text-gray"
                  disabled={isLoading}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  data-testid="create_task_btn"
                  className="w-full rounded-lg bg-primary py-[7.5px] text-sm font-bold text-white disabled:bg-gray-200 disabled:text-gray"
                  disabled={disabledSubmit}
                >
                  Create task
                </button>
              </div>
            </form>
          </FormProvider>
        )}
        {isTaskCreated && selectedPatient?.id && selectedTaskCategory?.label && (
          <TaskUpdated
            patientId={selectedPatient.id}
            taskCategory={selectedTaskCategory?.label}
            closeModal={handleCancel}
            type="created"
          />
        )}
      </div>
    </>
  );
};

export default CreateTaskForm;
