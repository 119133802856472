import { memo } from 'react';

import classNames from 'classnames';

import { MediaMessageProps } from './mediaMessage.types';
import { filePreview } from '../Message/message.settings';

const loadImageGallery = () => import('components/modals/TaskPopover/TaskDetails/ImageGallery');

const MediaMessage = ({
  fileName,
  isBlurred,
  isGrouped,
  self,
  src,
  text,
  handleImageClick
}: MediaMessageProps) => {
  const imageClasses = classNames(
    'h-[120px] max-w-[380px] shadow-sm rounded-xl object-cover',
    !text && !isGrouped && (self ? 'rounded-br-none' : 'rounded-bl-none')
  );

  const mediaWrapperClasses = classNames('w-fit rounded-2xl py-3 px-5', {
    'ml-auto bg-secondary-100': self,
    'mr-auto bg-gray-100': !self,
    'rounded-br-none': isGrouped && self,
    'rounded-bl-none': isGrouped && !self
  });

  return (
    <>
      {filePreview({
        fileName,
        imageClasses,
        mediaWrapperClasses,
        onImageClick: () => handleImageClick?.(src),
        onFocusImg: loadImageGallery,
        onMouseEnterImg: loadImageGallery,
        src,
        isBlurred
      })}
    </>
  );
};

export default memo(MediaMessage);
