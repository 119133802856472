import { memo } from 'react';

import Linkify from 'linkify-react';

import { DeletedMessageProps } from './deletedMessage.types';

const DeletedMessage = ({ deletedMessageText, linkifyOptionsClasses }: DeletedMessageProps) => {
  return (
    <Linkify
      options={{
        className: linkifyOptionsClasses,
        target: '_blank'
      }}
    >
      <p className="text-base">{deletedMessageText}</p>
    </Linkify>
  );
};

export default memo(DeletedMessage);
