import MonthlyAvailabilityTab from 'components/appointments/ManageAvailability/MonthlyAvailability/MonthlyAvailabilityTab';
import AdminLayout from 'layouts/Admin';
import ActivityFeed from 'pages/ActivityFeed';
import Admin from 'pages/Admin';
import AdminReports from 'pages/AdminReports';
import AgingConfig from 'pages/AgingConfig';
import StaffAnnouncements from 'pages/Announcements';
import Appointments from 'pages/Appointments';
import AppointmentTypes from 'pages/AppointmentTypes';
import Articles from 'pages/Articles';
import BillingConfiguration from 'pages/BillingConfiguration';
import CreateArticle from 'pages/CreateArticle';
import CustomerFeedback from 'pages/CustomerFeedback';
import FrontDeskRequestTypes from 'pages/FrontDeskRequestTypes';
import InternalAutomations from 'pages/InternalAutomations';
import InternalNoteTemplates from 'pages/InternalNoteTemplates';
import ManageRole from 'pages/ManageRole';
import ManageRoles from 'pages/ManageRoles';
import MedicalAssistantReports from 'pages/MedicalAssistantReports';
import OrderDetails from 'pages/OrderDetails/OrderDetails';
import Orders from 'pages/Orders';
import Patient from 'pages/Patient';
import PatientAutomations from 'pages/PatientAutomations';
import PatientMessageTemplates from 'pages/PatientMessageTemplates';
import Patients from 'pages/Patients';
import PhysicianReports from 'pages/PhysicianReports';
import Prospects from 'pages/Prospects';
import RequestMessageTemplates from 'pages/RequestMessageTemplates';
import Reviews from 'pages/Reviews';
import Settings from 'pages/Settings';
import SingleArticle from 'pages/SingleArticle';
import StaffCosts from 'pages/StaffCosts';
import StaffFeedback from 'pages/StaffFeedback';
import Staffs from 'pages/Staffs';
import Tasks from 'pages/Tasks';
import Teams from 'pages/Teams';
import { RouteObject } from 'react-router';
import { lazyWithRetries } from 'utils/common/lazy';

const Main = lazyWithRetries(() => import('../layouts/Main'));
const CrossSell = lazyWithRetries(() => import('../pages/CrossSell'));

const dashboardProtectedRoutesConfig: RouteObject[] = [
  { path: 'tasks', element: <Tasks /> },
  { path: 'activity-feed', element: <ActivityFeed /> },

  { path: 'appointments', element: <Appointments /> },
  {
    path: 'appointments/availability/:id',
    element: <MonthlyAvailabilityTab showCardWrapper />
  },

  { path: 'reports/admin', element: <AdminReports /> },
  { path: 'reports/medical-assistant', element: <MedicalAssistantReports /> },
  { path: 'reports/physician', element: <PhysicianReports /> },

  { path: 'patients', element: <Patients /> },
  { path: 'patients/prospect', element: <Prospects /> },
  { path: 'patient/:patientId', element: <Patient /> },

  { path: 'articles', element: <Articles /> },
  { path: 'articles/:articleId', element: <SingleArticle /> },
  { path: 'articles/create', element: <CreateArticle /> },
  { path: 'articles/update/:articleId', element: <CreateArticle /> },

  {
    path: 'admin',
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Admin />
      },
      { path: 'aging-configuration', element: <AgingConfig /> },
      { path: 'staff-announcements', element: <StaffAnnouncements /> },
      { path: 'system-settings', element: <Settings /> },
      { path: 'appointment-types', element: <AppointmentTypes /> },
      { path: 'front-desk-request-types', element: <FrontDeskRequestTypes /> },
      { path: 'patient-message-templates', element: <PatientMessageTemplates /> },
      { path: 'request-message-templates', element: <RequestMessageTemplates /> },
      { path: 'internal-note-templates', element: <InternalNoteTemplates /> },
      { path: 'manage-staff', element: <Staffs /> },
      { path: 'manage-teams', element: <Teams /> },
      { path: 'manage-roles', element: <ManageRoles /> },
      { path: 'manage-role/:roleId', element: <ManageRole /> },
      { path: 'internal-automations', element: <InternalAutomations /> },
      { path: 'patient-automation', element: <PatientAutomations /> },
      { path: 'staff-costs', element: <StaffCosts /> },
      { path: 'billing-config', element: <BillingConfiguration /> },
      { path: 'reviews', element: <Reviews /> },
      { path: 'staff-feedback', element: <StaffFeedback /> },
      { path: 'customer-feedback', element: <CustomerFeedback /> }
    ]
  },

  { path: 'orders', element: <Orders /> },
  { path: 'orders/:orderId', element: <OrderDetails /> }
];

export const protectedRoutesConfig: RouteObject[] = [
  {
    path: 'dashboard',
    children: [{ path: '*', element: <Main />, children: dashboardProtectedRoutesConfig }]
  },
  { path: 'cross-sell', element: <CrossSell /> }
];
