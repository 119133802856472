import { PERIOD_OPTIONS } from 'constants/user';

import { useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { DayEventHandler, DayPicker } from 'react-day-picker';
import { useClickAway } from 'react-use';
import {
  RoughPeriodDate,
  WomenHealthPick,
  WomenHealthProps
} from 'store/patientChart/patientChart.types';

import { WomensHealthPropsComponent } from './womensHealth.types';

const WomensHealth: React.FC<WomensHealthPropsComponent> = ({
  name,
  onUpdate,
  startDate,
  approxStartDate = '',
  loading = false
}) => {
  const [isUnsure, setIsUnsure] = useState(false);

  const handleUpdate = <T extends keyof WomenHealthProps>(
    value: T extends 'name'
      ? WomenHealthPick
      : T extends 'roughPeriodDate'
        ? RoughPeriodDate
        : string | null,
    type: T
  ) => {
    const newData: WomenHealthProps = {
      approxStartDate,
      name,
      startDate,
      [type]: value as WomenHealthProps[T]
    };

    if (type === 'startDate') {
      newData.approxStartDate = '';
    }

    onUpdate(newData);
  };

  const handleChangeUnsure = () => {
    const newStartDate = isUnsure ? dayjs().format(DateFormat.MM_DD_YYYY) : '';
    handleUpdate(newStartDate, 'startDate');
    setIsUnsure((prevState) => !prevState);
  };

  const parsedDate = (date: Date | string | null) =>
    date ? dayjs(date).format(DateFormat.MM_DD_YYYY) : null;

  const onChangeDate = (val: Date) => {
    if (parsedDate(val) !== parsedDate(startDate)) {
      handleUpdate(parsedDate(val), 'startDate');
    }
  };

  useEffect(() => {
    setIsUnsure(!startDate);
  }, [approxStartDate, startDate]);

  const ref = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const handleClick: DayEventHandler<React.MouseEvent> = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }
    onChangeDate(day);
    setShowModal(false);
  };

  useClickAway(ref, () => {
    setShowModal(false);
  });

  return (
    <div className="flex flex-col gap-2">
      <Common.RichRadioButton
        checked={name === '0'}
        disabled={loading}
        label="I&#8217;m post-menopausal"
        value="1"
        onChange={() => handleUpdate('0', 'name')}
      />
      <Common.RichRadioButton
        checked={name === '1'}
        disabled={loading}
        label="I&#8217;m currently pregnant"
        value="2"
        onChange={() => handleUpdate('1', 'name')}
      />
      <Common.RichRadioButton
        checked={name === '3'}
        disabled={loading}
        label="I&#8217;m currently nursing"
        value="3"
        onChange={() => handleUpdate('3', 'name')}
      />
      <Common.RichRadioButton
        checked={name === '2'}
        className="!overflow-visible"
        disabled={loading}
        label="Neither of the above"
        value="3"
        onChange={() => handleUpdate('2', 'name')}
      >
        {name === '2' && (
          <>
            <div className="mb-4 flex flex-col items-start gap-3 md:flex-row md:items-center">
              <div className="relative" ref={ref}>
                <Common.Input
                  disabled={isUnsure || loading}
                  inputClassName="!pr-3 cursor-pointer"
                  label="When did your last period start?"
                  placeholder="Select date"
                  preIcon="calendar"
                  value={dayjs(startDate ? dayjs(startDate).toDate() : new Date()).format(
                    DateFormat.MM_DD_YYYY
                  )}
                  readOnly
                  onFocus={() => setShowModal(!showModal)}
                />
                {showModal && (
                  <div className="absolute z-10 rounded-lg bg-white shadow md:-left-8 md:-top-5 md:text-sm">
                    <DayPicker
                      mode="single"
                      disabled={[
                        {
                          after: new Date(),
                          before: dayjs().subtract(62, 'years').toDate()
                        }
                      ]}
                      startMonth={dayjs().subtract(62, 'years').toDate()}
                      selected={startDate ? dayjs(startDate).toDate() : new Date()}
                      endMonth={new Date()}
                      showOutsideDays
                      onDayClick={handleClick}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="min-w-fit self-start md:mt-7 md:self-auto">
                <Common.Checkbox
                  checked={isUnsure}
                  disabled={loading}
                  onChange={handleChangeUnsure}
                >
                  I&#8217;m unsure
                </Common.Checkbox>
              </div>
            </div>
            {isUnsure && (
              <div className="max-w-[268px]">
                <Common.SelectAlt
                  disabled={loading}
                  label="If you can, please estimate approx number of weeks or months since it started."
                  options={PERIOD_OPTIONS}
                  placeholder="Weeks/months ago..."
                  value={approxStartDate}
                  hideSuccessState
                  onChange={(v) => handleUpdate(v, 'approxStartDate')}
                />
              </div>
            )}
          </>
        )}
      </Common.RichRadioButton>
    </div>
  );
};

export default WomensHealth;
