import { DEFAULT_LIMIT_LOADED_ONCE_MESSAGES } from 'constants/chat';

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { MessageType } from 'enums/messages';
import { useSocketConnection } from 'hooks/common/messages/useSocketConnection';
import { useAppDispatch } from 'hooks/redux';
import { SocketResponseProps } from 'models/socket.types';
import { useLocation } from 'react-router';
import socket from 'socket/socketMessages';
import type { Socket } from 'socket.io-client';
import { updateChannel } from 'store/channels/channelsSlice';
import {
  clearMessages,
  useDeleteMessageMutation,
  useLazyGetCareMessagesQuery,
  useUpdateMessageMutation
} from 'store/chat/chatSlice';
import { eventCallBack, logStyles } from 'utils/socket';

import {
  CareChannelDetailsProps,
  DeleteMessageFromServerParams,
  MessagesContextProps,
  NewMessageToServerParams,
  UpdatedMessageToServerParams
} from './messagesContext.types';

const MessagesContext = createContext<MessagesContextProps | undefined>(undefined);

const MessagesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [getCareMessages] = useLazyGetCareMessagesQuery();
  const [updateMessage] = useUpdateMessageMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const [channelDetails, setChannelDetails] = useState<CareChannelDetailsProps | null>(null);

  const { socketInit } = useSocketConnection({ socket: socket });

  const [isConnected, setIsConnected] = useState(socket.connected);

  /**
   * Marks messages as seen for a specific user in a specific channel.
   *
   * @param {string} channelId - The ID of the channel where the messages are marked as seen.
   * @param {string} id - The ID of the user for whom the messages are marked as seen.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---markseen|Socket event details}
   */
  const markSeen = useCallback(({ channelId, id }: { channelId: string; id: string }) => {
    if (channelId && id && socket.connected) socket.emit('markSeen', { channelId, userId: id });
  }, []);

  /**
   * Joins a Care Channel, disconnecting the user from other Care Channels.
   * On success, emits channelDetails and historyCatchUp events.
   *
   * @param {string} roomId - The ID of the Care Channel to join.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---joinroom|Socket event details}
   */
  const joinRoom = useCallback(
    (roomId: string, unreadMessageCount: number) => {
      if (roomId) {
        // if there are more than 10 unread messages, load all unread messages at once
        // to have ability to scroll to the first unread message;
        const limit =
          unreadMessageCount > DEFAULT_LIMIT_LOADED_ONCE_MESSAGES
            ? unreadMessageCount
            : DEFAULT_LIMIT_LOADED_ONCE_MESSAGES;

        // Load messages immediately when a channel is selected, independent of socket connection
        getCareMessages({ channelId: roomId, limit, type: MessageType.Medical });

        // Join the socket room without waiting for response to load messages
        socket.emit('joinRoom', roomId, (response: SocketResponseProps) => {
          eventCallBack('joinRoom', response);
        });
      }
    },
    [getCareMessages]
  );

  /**
   * Sends a new message to the server.
   * The server will then broadcast this message to all other users in the same Care Channel.
   *
   * @param {Object} newMessage - The new message to be sent.
   * @param {boolean} isAppointmentChat - Whether the message was sent durning appointment.
   * @returns {void}
   * @throws Will throw an error if the socket is not connected.
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---emit---newmessagetoserver|Socket event details}
   */
  const sendMessageToServer = useCallback(
    ({
      newMessage,
      isAppointmentChat
    }: {
      newMessage: NewMessageToServerParams;
      isAppointmentChat: boolean;
    }) => {
      if (socket.connected) socket.emit('newMessageToServer', newMessage, isAppointmentChat);
    },
    []
  );

  /**
   * Sends updated message to the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const sendUpdatedMessageToServer = (params: UpdatedMessageToServerParams) => {
    updateMessage(params);
  };

  /**
   * Deletes message from the server.
   *
   * @param {Object} params - object with the necessary params.
   * @returns {void}
   */
  const deleteMessageFromServer = (params: DeleteMessageFromServerParams) => {
    deleteMessage(params);
  };

  /**
   * Handles the update of unread message count in a Care Channel.
   * This function is triggered when a new message is sent in the Care Channel.
   *
   * @param {Object} result - The result object containing the updated unread message count.
   * @param {string} result.channelId - The ID of the Care Channel where the unread message count is updated.
   * @param {string} [result.latestMessage] - The latest message sent in the Care Channel.
   * @param {string} result.latestMessageDate - The date when the latest message was sent.
   * @param {number} result.unreadMessageCount - The updated unread message count.
   * @returns {void}
   * @see {@link https://dev2.lifemd.dev/socket-docs#messages---listen---unreadmessageupdated|Socket event details}
   */
  const handleUnreadMessageCount = useCallback(
    (result: {
      channelId: string;
      latestMessage?: string;
      latestMessageDate: string;
      unreadMessageCount: number;
    }) => {
      dispatch(updateChannel(result));
    },
    [dispatch]
  );

  const closeChannel = useCallback(() => {
    dispatch(clearMessages());
  }, [dispatch]);

  const handleDisconnect = useCallback(() => {
    socket.disconnect();
    closeChannel();
  }, [closeChannel]);

  // Disconnect socket on route change
  useEffect(() => {
    if (socket.connected) handleDisconnect();
  }, [handleDisconnect, location.pathname]);

  useEffect(() => {
    socketInit();

    return () => {
      handleDisconnect();
    };
  }, [socketInit, handleDisconnect]);

  useEffect(() => {
    const onConnect = () => {
      console.info('%c successfully connected to /messages namespace', logStyles.connectMsgStyles);
      setIsConnected(true);
    };

    socket.on('connect', onConnect);

    return () => {
      socket.off('connect', onConnect);
    };
  }, []);

  /**
   * Sets up a listener for the 'channelDetails' event from the socket.
   * When the 'channelDetails' event is received, it updates the channel details state.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'channelDetails' event listener and resets the channel details state.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-any|Socket.IO event details}
   */
  useEffect(() => {
    socket.on('channelDetails', setChannelDetails);

    return () => {
      socket.off('channelDetails', () => setChannelDetails(null));
    };
  }, []);

  /**
   * Sets up a listener for the 'disconnect' event from the socket.
   * When the 'disconnect' event is received, it sets the isConnected state to false.
   * If the disconnection was initiated by the server, it tries to reconnect manually.
   * Otherwise, the socket will automatically try to reconnect.
   * It also logs the reason for the disconnection.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'disconnect' event listener.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-disconnect|Socket.IO event details}
   */
  useEffect(() => {
    const onDisconnect = (reason: Socket.DisconnectReason) => {
      setIsConnected(false);
      if (reason === 'io server disconnect') {
        // The disconnection was initiated by the server, try to reconnect manually
        socket.connect();
      }
      // else the socket will automatically try to reconnect
      console.info(
        `%c~ file: MessagesContext.tsx:117 ~ socket.on ~ reason: ${reason}`,
        logStyles.disconnectMsgStyles
      );
    };

    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  /**
   * Sets up a listener for the 'connect_error' event from the socket.
   * When the 'connect_error' event is received, it checks if the error message is 'AUTH_EXPIRED'.
   * If so, it reinitialize the socket connection.
   * Also, it logs the error message.
   *
   * Also, it cleans up the listener when the component is unmounted or dependencies change.
   * The cleanup function removes the 'connect_error' event listener.
   *
   * @see {@link https://socket.io/docs/v4/client-api/#event-connect_error|Socket.IO event details}
   */
  useEffect(() => {
    const handleSocketError = (error: Error) => {
      if (error.message === 'AUTH_EXPIRED' || error.message === 'AUTH_INVALID') {
        // reinitialize socket connection with force refresh since auth is expired
        socketInit(true);
      }
      console.error(
        `%c~ file: MessagesContext.tsx:131 ~ socket.on ~ error: ${error}`,
        logStyles.errorMsgStyles
      );
    };

    socket.on('connect_error', handleSocketError);

    return () => {
      socket.off('connect_error', handleSocketError);
    };
  }, [socketInit]);

  useEffect(() => {
    socket.on('unreadMessageUpdated', handleUnreadMessageCount);

    return () => {
      socket.off('unreadMessageUpdated', handleUnreadMessageCount);
    };
  }, [handleUnreadMessageCount]);

  const value = useMemo(
    () => ({
      setChannelDetails,
      channelDetails,
      isConnected,
      joinRoom,
      markSeen,
      closeChannel,
      sendMessageToServer,
      sendUpdatedMessageToServer,
      deleteMessageFromServer
    }),
    [
      channelDetails,
      closeChannel,
      isConnected,
      joinRoom,
      markSeen,
      sendMessageToServer,
      sendUpdatedMessageToServer,
      deleteMessageFromServer
    ]
  );

  return <MessagesContext.Provider value={value}>{children}</MessagesContext.Provider>;
};

const useMessages = () => {
  const context = useContext(MessagesContext);
  if (context === undefined) {
    throw new Error(`useMessages must be used within a MessagesProvider`);
  }
  return context;
};

MessagesProvider.displayName = 'Messages Provider';

export { MessagesProvider, useMessages };
