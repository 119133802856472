import React, { Fragment } from 'react';

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import type { MoreOptionsTypes } from './moreOptions.types';

const MoreOptions: React.FC<MoreOptionsTypes> = ({
  handleDelete,
  handleDownload,
  handleAddTags,
  handleViewDetails,
  showDeleteItem,
  shouldEditTags
}) => {
  const menuIconClasses =
    'flex items-center px-3 py-2 text-sm font-semibold gap-2 data-[focus]:bg-gray-100 cursor-pointer rounded-md';

  return (
    <Menu>
      <div>
        <MenuButton>
          <Common.Icon name="dots-vertical" className="ml-6 size-4" aria-hidden="true" />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom end"
          className="z-[110] rounded-md bg-white p-2 shadow-lg ring-1 ring-black/5 focus:outline-none"
        >
          <div className="w-48 overflow-hidden">
            <MenuItem>
              <a className={menuIconClasses} onClick={handleDownload}>
                <Common.Icon name="download" /> Download
              </a>
            </MenuItem>
            <MenuItem>
              <a className={menuIconClasses} onClick={handleAddTags}>
                <Common.Icon name="plus" /> {shouldEditTags ? 'Edit tags' : 'Add tags'}
              </a>
            </MenuItem>
            <MenuItem>
              <a className={menuIconClasses} onClick={handleViewDetails}>
                <Common.Icon name="list-outline-simple" /> View details
              </a>
            </MenuItem>
            {showDeleteItem && (
              <MenuItem>
                <a className={classNames(menuIconClasses, 'text-red-500')} onClick={handleDelete}>
                  <Common.Icon name="document" /> Delete
                </a>
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default MoreOptions;
