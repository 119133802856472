import { DEFAULT_LIMIT_LOADED_ONCE_MESSAGES } from 'constants/chat';

import { useEffect, useRef, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import AppointmentSystemMessage from 'components/common/Chat/AppointmentSystemMessage';
import DateLabel from 'components/common/Chat/DateLabel';
import Message from 'components/common/Chat/Message';
import Loader from 'components/common/Loader';
import { getLatestMessage } from 'contexts/MessagesContext/messageContext.settings';
import { FormattedMessagesProps } from 'contexts/MessagesContext/messagesContext.types';
import { MessageEvents, MessageType } from 'enums/messages';
import { TaskCategories } from 'enums/taskCategories';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { InView, useInView } from 'react-intersection-observer';
import { selectChannels } from 'store/channels/channelsSlice';
import { selectChat, selectMessages, useLazyGetCareMessagesQuery } from 'store/chat/chatSlice';
import { selectTask } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';

const selectMessagesState = createSelector(
  [selectUser, selectChat, selectMessages, selectChannels, selectTask],
  (user, chat, messages, channels, task) => ({
    userId: user._id,
    messages,
    totalCount: chat.totalCount,
    channel: channels.currentChannel,
    taskCategory: task.taskDetails.category
  })
);

const appointmentMessagesEvents = [
  MessageEvents.AppointmentCreated,
  MessageEvents.AppointmentComplete,
  MessageEvents.AppointmentCancelled,
  MessageEvents.AppointmentMissed
];

const MessagesList: React.FC<{
  type: Exclude<MessageType, MessageType.StaffNote | MessageType.SMS>;
  handleImageClick: (filePath: string) => void;
}> = ({ type, handleImageClick }) => {
  const useMessages = useClientMessages({ type });
  const { channelDetails, markSeen } = useMessages();
  const [getCareMessages, { isFetching, isLoading: isLoadingMessages }] =
    useLazyGetCareMessagesQuery();
  const { userId, messages, totalCount, channel, taskCategory } =
    useAppSelector(selectMessagesState);
  const unreadMessageCount = channel?.unreadMessageCount || 0;

  const isLoading = isFetching && isLoadingMessages;
  const { inView: isFirstMessageInView, ref } = useInView({ threshold: 1 });

  const [skip, setSkip] = useState(1);
  const [firstUnseenMessageId, setFirstUnseenMessageId] = useState<string | null>(null);

  const firstUnseenSet = useRef(false); // Ref to track if firstUnseenMessageId has been set

  useEffect(() => {
    const skipCount =
      totalCount && skip <= Math.floor(totalCount / DEFAULT_LIMIT_LOADED_ONCE_MESSAGES);
    const loadMoreMessages =
      isFirstMessageInView && (channelDetails || channel?._id) && !isLoading && skipCount;

    if (loadMoreMessages && (channelDetails?._id || channel?._id)) {
      getCareMessages({
        limit: DEFAULT_LIMIT_LOADED_ONCE_MESSAGES,
        pageNo: skip,
        channelId: channelDetails?._id || channel?._id || '',
        type
      });
      setSkip((prevState) => prevState + 1);
    }
    // NOTE: this effect must run only when inView value is change
  }, [isFirstMessageInView]);

  useEffect(() => {
    // reset pagination on change channel
    setSkip(1);
  }, [channelDetails?._id, channel?._id]);

  useEffect(() => {
    if (!firstUnseenSet.current && !isEmpty(messages)) {
      const receivedMessages = Object.values(messages).flat() || [];
      const firstUnreadMessage =
        unreadMessageCount &&
        receivedMessages.length &&
        Object.values(messages).flat()[receivedMessages.length - unreadMessageCount];

      if (firstUnreadMessage) {
        setFirstUnseenMessageId(firstUnreadMessage.id);
        firstUnseenSet.current = true;
      }
    }
  }, [messages, unreadMessageCount]);

  useEffect(() => {
    if (firstUnseenMessageId) {
      const element = document.getElementById(firstUnseenMessageId);
      const container = document.querySelector('[data-testid="task_slide_panel"]');

      // NOTE: scroll to first unseen message ONLY when the task category is Messages
      if (element && container && taskCategory === TaskCategories.Messages) {
        const timeoutId = setTimeout(() => {
          container.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }, 500);

        return () => clearTimeout(timeoutId);
      }
    }
  }, [firstUnseenMessageId]);

  const latestMessage = getLatestMessage(messages);

  const handleChangeInView = (inView: boolean, message: FormattedMessagesProps) => {
    if (message.id === latestMessage?.id && inView && channelDetails?._id) {
      markSeen({ channelId: channelDetails?._id, id: userId });
    }
  };

  return (
    <>
      <Loader isVisible={isFetching} />
      {messages &&
        !isEmpty(messages) &&
        Object.entries(messages).map(([key, value], index) => {
          if (index === 0) {
            return (
              <div key={key}>
                <DateLabel date={key} key={key} />
                {value.map((message, messageIndex) => {
                  if (
                    message?.system &&
                    message?.event &&
                    appointmentMessagesEvents.includes(message.event)
                  ) {
                    return (
                      <AppointmentSystemMessage
                        message={message}
                        key={message.id}
                        ref={messageIndex === 0 ? ref : null}
                      />
                    );
                  }
                  const messageDate = new Date(
                    message.deletedDate ? message.deletedDate : message.date
                  );

                  return (
                    <InView
                      key={message.id}
                      triggerOnce
                      onChange={(inView) => handleChangeInView(inView, message)}
                    >
                      <Message
                        date={messageDate}
                        deletedBy={message.deletedBy}
                        fileName={message.fileName}
                        handleImageClick={handleImageClick}
                        history={message.history}
                        id={message.id}
                        isBlurred={message.isBlurred}
                        isGrouped={message.isGrouped}
                        key={message.id}
                        name={message.author}
                        profileImage={message?.profileImage}
                        ref={messageIndex === 0 ? ref : null}
                        seenByPatient={message.seenPatient}
                        self={userId === message.userId}
                        sentViaSms={message?.sentViaSms}
                        src={message.filePath}
                        text={message.message}
                        type={type}
                        userType={message.sentBy}
                      />
                    </InView>
                  );
                })}
              </div>
            );
          } else {
            return (
              <div key={key}>
                <DateLabel date={key} key={key} />
                {value.map((message) => {
                  if (
                    message?.system &&
                    message?.event &&
                    appointmentMessagesEvents.includes(message?.event)
                  ) {
                    return <AppointmentSystemMessage message={message} key={message?.id} />;
                  }
                  const messageDate = new Date(
                    message.deletedDate ? message.deletedDate : message.date
                  );
                  return (
                    <InView
                      key={message.id}
                      triggerOnce
                      onChange={(inView) => handleChangeInView(inView, message)}
                    >
                      <Message
                        date={messageDate}
                        deletedBy={message.deletedBy}
                        fileName={message.fileName}
                        handleImageClick={handleImageClick}
                        history={message.history}
                        id={message.id}
                        isBlurred={message.isBlurred}
                        isGrouped={message.isGrouped}
                        key={message.id}
                        name={message.author}
                        profileImage={message?.profileImage}
                        seenByPatient={message.seenPatient}
                        self={userId === message.userId}
                        sentViaSms={message?.sentViaSms}
                        src={message.filePath}
                        text={message.message}
                        type={type}
                        userType={message.sentBy}
                      />
                    </InView>
                  );
                })}
              </div>
            );
          }
        })}
    </>
  );
};

export default MessagesList;
