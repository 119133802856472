import { useState } from 'react';

import ManageMessageIcon from 'assets/icons/chat/ManageMessage.svg?react';

import ManageMessageDropdown from '../ManageMessageDropdown';
import ManageMessageTooltip from '../ManageMessageTooltip';
import { ManageMessageIconWrapperProps } from './manageMessageIconWrapper.types';

const ManageMessageIconWrapper: React.FC<ManageMessageIconWrapperProps> = ({
  text,
  src,
  seenByPatient,
  setModalVisible,
  setIsEditing
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false);
  };

  const handleDeleteClick = () => setModalVisible(true);

  const handleEditClick = () => {
    setIsEditing(true);
    setIsDropdownVisible(false);
  };

  const showDropdown = isDropdownVisible && !seenByPatient;
  const showTooltip = isDropdownVisible && seenByPatient;

  const messageActionClasses = 'absolute right-0 top-0 z-10';

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ManageMessageIcon
        data-testid="message-arrow-icon"
        width={20}
        height={20}
        fill="#fff"
        className={`${messageActionClasses} ${!!src ? 'mr-[-6px] mt-[-20px]' : '-mr-1 -mt-2'}`}
      />
      {showDropdown && (
        <>
          <div className={`${messageActionClasses} mr-[-6px] mt-[-20px] h-16 w-5`} />
          <ManageMessageDropdown
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
            text={text}
            isFile={!!src}
          />
        </>
      )}
      {showTooltip && <ManageMessageTooltip />}
    </div>
  );
};

export default ManageMessageIconWrapper;
