import { CATEGORY_LABELS } from 'constants/appointmentType';

import upperFirst from 'lodash/upperFirst';

const formatLabel = (categoryLabel: string, isSingular?: boolean) => {
  const mainCategoryLabel = categoryLabel.endsWith('s')
    ? categoryLabel.slice(0, -1)
    : categoryLabel;

  const currentCategories = CATEGORY_LABELS.find(
    (category) => category.singular.toLowerCase() === mainCategoryLabel.toLowerCase()
  );

  if (currentCategories) {
    return isSingular ? currentCategories?.singular : currentCategories?.plural;
  }
  return upperFirst(categoryLabel);
};

export const formatCategoryLabel = (categoryLabel: string, isSingular?: boolean) => {
  if (categoryLabel.includes('•')) {
    return categoryLabel.replace(
      /^(.*?) •/,
      (_, mainCategory) => formatLabel(mainCategory, isSingular) + ' •'
    );
  }

  return formatLabel(categoryLabel, isSingular);
};
