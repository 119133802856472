import { memo } from 'react';

import Linkify from 'linkify-react';

import { TextMessageProps } from './textMessage.types';
import { parseString } from '../Message/message.settings';

const TextMessage = ({ text, linkifyOptionsClasses }: TextMessageProps) => {
  return (
    <Linkify
      options={{
        className: linkifyOptionsClasses,
        target: '_blank'
      }}
    >
      {parseString(text)}
    </Linkify>
  );
};

export default memo(TextMessage);
