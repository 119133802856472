import { useCallback, useEffect, useMemo, useRef } from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import uitoolkit, { CustomizationOptions } from '@zoom/videosdk-ui-toolkit';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import LightCoolBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-cool.webp';
import LightNeutralBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-neutral.webp';
import LightWarmBg from 'assets/images/virtual-backgorunds/lifemd-bg-light-warm.webp';
import VirtualBg from 'assets/images/virtual-backgorunds/lifemd-bg.webp';
import { useSearchParams } from 'react-router';
import { useLazyGetExternalZoomTokenQuery } from 'store/appointments/appointmentsSlice';
import { isFirefoxBrowser, isSafariBrowser } from 'utils/helpers';

const ExternalVideoCall = () => {
  const [getExternalZoomToken] = useLazyGetExternalZoomTokenQuery();
  const [searchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointmentId');

  const isSafari = useMemo(() => isSafariBrowser(), []);
  const isFirefox = useMemo(() => isFirefoxBrowser(), []);

  const sessionContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    sessionContainerRef.current = document.getElementById('sessionContainer') as HTMLDivElement;
  }, []);

  const config: CustomizationOptions = {
    videoSDKJWT: '',
    sessionName: '',
    userName: 'Physician',
    featuresOptions: {
      video: {
        enable: true,
        virtualBackground: !isFirefox,
        enforceMultipleVideos: isSafari
      },
      audio: {
        enable: true
      },
      secondaryAudio: {
        enable: false
      },
      virtualBackground: {
        enable: !isFirefox,
        enforceVirtualBackground: !isFirefox,
        virtualBackgrounds: [
          {
            url: VirtualBg
          },
          {
            url: LightCoolBg
          },
          {
            url: LightNeutralBg
          },
          {
            url: LightWarmBg
          }
        ]
      },
      users: {
        enable: true
      },
      chat: {
        enable: false,
        enableEmoji: false
      },
      invite: {
        enable: false
      },
      theme: {
        enable: true,
        defaultTheme: 'green'
      },
      phone: {
        enable: false
      },
      recording: {
        enable: false
      },
      caption: {
        enable: false
      },
      share: {
        enable: true
      },
      settings: {
        enable: true
      },
      subsession: {
        enable: false
      },
      preview: {
        enable: true,
        isAllowModifyName: false
      },
      leave: {
        enable: true
      },
      footer: {
        enable: true
      },
      header: {
        enable: true
      },
      feedback: {
        enable: false
      }
    }
  };

  const getVideoSDKJWT = async () => {
    if (!appointmentId) return;

    document.getElementById('join-flow')!.style.display = 'none';
    try {
      const res = await getExternalZoomToken(appointmentId).unwrap();
      config.videoSDKJWT = res.token;
      config.sessionName = res.roomName;
      joinSession();
    } catch (error) {
      console.error('Failed to get token:', error);
    }
  };

  const joinSession = () => {
    console.info(config);
    if (sessionContainerRef.current) {
      uitoolkit.joinSession(sessionContainerRef.current, config);
      uitoolkit.onSessionClosed(sessionClosed);
    }
  };

  const sessionClosed = useCallback(() => {
    console.info('session closed');
    if (sessionContainerRef.current) {
      uitoolkit.leaveSession();
      uitoolkit.closeSession(sessionContainerRef.current);
    }
    document.getElementById('join-flow')!.style.display = 'block';
    window.location.reload();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', sessionClosed);

    return () => {
      window.removeEventListener('beforeunload', sessionClosed);
    };
  }, [sessionClosed]);

  return (
    <div className="external-video-call-container">
      <main>
        <div id="join-flow">
          <Common.Logo name="logo" className="mx-auto my-8" />
          <h1>Ready to Connect with Your Patient?</h1>
          <p>Check your setup and join the call when you're ready.</p>
          <button onClick={getVideoSDKJWT} className="mt-5 cursor-pointer p-2">
            Join Session
          </button>
        </div>
        <div id="sessionContainer"></div>
      </main>
    </div>
  );
};

export default ExternalVideoCall;
