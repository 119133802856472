import { memo, useEffect, useRef, useState } from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import BasicConfirmation from 'components/modals/BasicConfirmation';
import EditForm from 'components/patient/Channel/EditForm/EditForm';
import { MessageType } from 'enums/messages';
import { useClientMessages } from 'hooks/common/useClientMessages';
import { useToggle } from 'react-use';
import { parseHTMLToText } from 'utils/common/parseHTML';

import {
  DELETE_MESSAGE_CONFIRMATION_SUBHEADER_TEXT,
  DELETED_MESSAGE_TEXT,
  getProfileImageWrapperClassName,
  parseEditedString
} from './message.settings';
import { MessageProps } from './message.types';
import DeletedMessage from '../DeletedMessage';
import ManageMessageIconWrapper from '../ManageMessageIconWrapper';
import MediaMessage from '../MediaMessage';
import MessageMetaData from '../MessageMetaData';
import TextMessage from '../TextMessage';

const Message = ({
  date,
  deletedBy,
  fileName,
  handleImageClick,
  history,
  id,
  isBlurred,
  isGrouped,
  isStaffNote,
  name,
  profileImage,
  seenByPatient,
  self,
  sentViaSms,
  src,
  text,
  type,
  userType,
  ref
}: MessageProps) => {
  const linkifyOptionsClasses = 'text-base underline text-primary break-all';
  const editFormClasses =
    'min-h-9 w-full resize-none items-center overflow-hidden rounded-2xl border-none bg-white pr-[140px] focus:ring-transparent';
  const useMessages = useClientMessages({ type });
  const { deleteMessageFromServer } = useMessages();
  const isEdited = history && history.length > 0;
  const isDeleted = !!deletedBy;

  const [isEditedMessagesDisplayed, toggleIsEditedMessageDisplayed] = useToggle(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const [showAvatar, setShowAvatar] = useState(false);

  const messageRef = useRef<HTMLDivElement>(null);
  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<HTMLDivElement>(null);

  const deletedMessageText = DELETED_MESSAGE_TEXT.replace('{name}', deletedBy || name || '');

  useEffect(() => {
    if (isEditing) {
      // Set the height of the editorWrapper to match the original message's height
      if (messageRef.current && editorWrapperRef.current) {
        editorWrapperRef.current.style.height = `${messageRef.current.offsetHeight}px`;
      }

      // Start showing the avatar after a delay
      const timer = setTimeout(() => {
        setShowAvatar(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setShowAvatar(false); // Hide avatar when not editing
    }
  }, [isEditing]);

  useEffect(() => {
    // Reset styles when editing mode changes
    if (messageRef.current) messageRef.current.style.cssText = '';
  }, [isEditing]);

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedText(text);
    setShowAvatar(false);
    if (messageRef.current) messageRef.current.style.cssText = '';
  };

  const handleCancel = () => setModalVisible(false);

  const handleDeleteMessage = () => {
    setModalVisible(false);
    deleteMessageFromServer({ messageId: id, type });

    // Hide the edited messages list when the core message is deleted
    if (isEditedMessagesDisplayed) {
      toggleIsEditedMessageDisplayed();
    }
  };

  const showBasicConfirmation = !isDeleted && self;
  const showEditedMessagesToggle = isEdited && !isDeleted && self && type !== MessageType.SMS;
  const showEditForm = isEditing && type !== MessageType.SMS;
  const showManageMessageIcon = !isDeleted && type && type !== MessageType.SMS && self;

  const isViaBraze = sentViaSms;
  const containerClasses = classNames('flex flex-col gap-3 mb-1', {
    'pb-3': !isGrouped
  });

  const messageClasses = classNames('relative w-fit py-3 px-5 rounded-2xl', {
    'border border-solid border-gray-300': !self && isStaffNote && !isDeleted,
    'border border-solid border-secondary-400': self && isStaffNote && !isDeleted,
    'bg-secondary-100': self && !isStaffNote && !isDeleted,
    'bg-gray-100': !self && !isStaffNote && !isDeleted,
    'rounded-br-none': !isGrouped && self,
    'rounded-bl-none': !isGrouped && !self,
    '-mt-2': !!src && !isDeleted,
    'my-1': isDeleted,
    'bg-white outline-dashed outline-gray-300 text-gray-500': isDeleted
  });

  const messageEditClasses = classNames(
    'relative w-full pl-2 rounded-2xl flex justify-between bg-white border-blue-500 border-2'
  );

  const wrapperMessageBlockClasses = classNames(
    `flex flex-col gap-3 ${isEditing ? 'w-full py-1' : ''}`,
    {
      'items-end': self
    }
  );

  const messageWrapperClasses = getProfileImageWrapperClassName(self, isGrouped);

  const normalProfileImageClasses = classNames('block mb-[26px]', self ? 'ml-3' : 'mr-3');

  const editingProfileImageClasses = classNames(
    'block bottom-8 transition-opacity duration-10 ease-in-out',
    {
      'opacity-0': !showAvatar,
      'opacity-100': showAvatar,
      [self ? 'ml-3' : 'mr-3']: true
    }
  );

  const editedMessagesListClasses = classNames(
    'flex gap-2 w-fit rounded-2xl py-3 px-5 rounded-br-none border mr-12 border-grey-300 ml-auto'
  );

  return (
    <div id={id} className={containerClasses} ref={ref}>
      {isEditedMessagesDisplayed &&
        history &&
        history.map((editedMessage) => (
          <div className={editedMessagesListClasses}>
            {parseEditedString(editedMessage.message)}
          </div>
        ))}
      <div data-testid="message" className={messageWrapperClasses}>
        {!isGrouped && (
          <Common.ProfileImage
            src={profileImage}
            className={isEditing ? editingProfileImageClasses : normalProfileImageClasses}
          />
        )}
        <div className={wrapperMessageBlockClasses}>
          {/* Unified message renderer for all message types */}
          {showEditForm && text ? (
            <div
              className={classNames(messageEditClasses)}
              ref={editorWrapperRef} // EditorWrapper ref for setting height
            >
              <EditForm
                className={editFormClasses}
                editedText={editedText as string}
                messageId={id}
                originalMessageText={text}
                ref={editorRef}
                setEditedText={setEditedText}
                setIsEditing={handleCancelEdit}
                type={type}
              />
            </div>
          ) : (
            <div className={src ? 'mt-5' : ''}>
              {showManageMessageIcon && (
                <ManageMessageIconWrapper
                  text={text}
                  src={src}
                  setModalVisible={setModalVisible}
                  setIsEditing={setIsEditing}
                  seenByPatient={seenByPatient}
                />
              )}

              {/* Media message container - rendered for both media-only and text+media messages */}
              {src && !isDeleted && (
                <div className={`-mt-3 flex justify-end ${text ? 'mb-3' : ''}`}>
                  <MediaMessage
                    fileName={fileName}
                    handleImageClick={handleImageClick}
                    src={src}
                    isBlurred={isBlurred}
                    text={text}
                    self={self}
                    isGrouped={isGrouped}
                  />
                </div>
              )}

              {/* Determine if we should render a message container */}
              {(isDeleted || text) && (
                <div data-testid="msg_container" className={messageClasses} ref={messageRef}>
                  {isDeleted ? (
                    <DeletedMessage
                      deletedMessageText={deletedMessageText}
                      linkifyOptionsClasses={linkifyOptionsClasses}
                    />
                  ) : (
                    text && (
                      <TextMessage text={text} linkifyOptionsClasses={linkifyOptionsClasses} />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          <MessageMetaData
            userType={userType}
            isGrouped={isGrouped}
            self={self}
            name={name}
            date={date}
            showEditedMessagesToggle={showEditedMessagesToggle}
            seenByPatient={seenByPatient}
            isViaBraze={isViaBraze}
            toggleIsEditedMessageDisplayed={toggleIsEditedMessageDisplayed}
            isEditedMessagesDisplayed={isEditedMessagesDisplayed}
          />
          {showBasicConfirmation && (
            <BasicConfirmation
              onClose={handleCancel}
              isOpen={isModalVisible}
              onConfirm={handleDeleteMessage}
              isLoading={false}
              confirmButtonText="Delete"
              headerText="Delete message"
              subHeaderText={
                parseHTMLToText({
                  text: DELETE_MESSAGE_CONFIRMATION_SUBHEADER_TEXT,
                  className: 'text-gray-700'
                }) as string
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

Message.displayName = 'Message';

export default memo(Message);
