import { memo } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { Role } from 'enums/role';

import { MessageMetaDataProps } from './messageMetaData.types';
import { getUserRole } from '../Message/message.settings';

const MessageMetaData = ({
  isGrouped,
  self,
  name,
  date,
  showEditedMessagesToggle,
  seenByPatient,
  userType,
  isViaBraze,
  toggleIsEditedMessageDisplayed,
  isEditedMessagesDisplayed
}: MessageMetaDataProps) => {
  const renderEditedMessagesToggle = () => (
    <span
      className="ml-1 flex cursor-pointer px-1 text-mXs font-semibold text-blue-700"
      onClick={() => toggleIsEditedMessageDisplayed()}
    >
      {isEditedMessagesDisplayed ? 'Close' : 'Edited'}
    </span>
  );

  const nameClasses = classNames('flex text-gray items-center text-xs text-left -mt-2 mb-2 gap-1', {
    'ml-auto flex-row-reverse': self,
    'mr-auto': !self
  });
  const seenStatusClasses = classNames('text-mXs text-gray-500', self ? 'text-right' : 'text-left');
  const roleTagClasses = classNames('rounded px-1 text-xs', {
    'text-yellow-600 bg-yellow-100': userType?.name === 'Patient',
    'text-blue-600 bg-blue-100': userType?.name === Role.PH,
    'ml-1': !self,
    'mr-1': self
  });

  return (
    <>
      {!isGrouped && (
        <div data-testid="message_datestamp" className={nameClasses}>
          {showEditedMessagesToggle && renderEditedMessagesToggle()}
          <span className={seenStatusClasses}>
            {!self
              ? `${name} • ${dayjs(date).format(DateFormat.h_mm_a)}`
              : `${seenByPatient ? 'Seen • ' : 'Sent • '} ${dayjs(date).format(DateFormat.h_mm_a)}`}
          </span>
          {getUserRole(userType?.name) && (
            <span className={roleTagClasses}>{getUserRole(userType?.name)}</span>
          )}
          {isViaBraze && (
            <span className="ml-1 flex rounded bg-gray-100 px-1 text-xs text-gray-600">
              Sent via SMS
            </span>
          )}
        </div>
      )}
      {isGrouped && showEditedMessagesToggle && (
        <div data-testid="message_datestamp" className={nameClasses}>
          {renderEditedMessagesToggle()}
        </div>
      )}
    </>
  );
};

export default memo(MessageMetaData);
