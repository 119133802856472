import { LENGTH_OPTIONS, LENGTH_OPTIONS_WITH_DEFAULT } from 'constants/appointmentType';

import { useEffect, useState } from 'react';

import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import { OptionProps } from 'components/common/form/Select/select.types';
import Loader from 'components/common/Loader';
import ControlledSelect from 'components/forms/controlled/ControlledSelect';
import { useFormContext } from 'react-hook-form';
import { useGetAppointmentLengthsQuery } from 'store/appointmentLength/appointmentLengthSlice';
import { LengthByPlan } from 'store/appointments/appointments.types';

import { getDefaultLengthsData, returnLengthOption } from './appointmentTypeLengths.settings';
import {
  AppointmentTypeLengthsFormProps,
  AppointmentTypeLengthsProps,
  LengthsItem,
  LengthsProps
} from './appointmentTypeLengths.types';
import { handleRequired } from '../appointmentType.settings';

const AppointmentTypeLengths: React.FC<AppointmentTypeLengthsProps> = ({ isEditDefault }) => {
  const { data: appointmentLengthsData, isLoading, isFetching } = useGetAppointmentLengthsQuery();
  const [appointmentLengths, setAppointmentLengths] = useState<LengthsProps>({
    initial: [],
    subsequent: []
  });
  const { control, resetField, formState, watch } =
    useFormContext<AppointmentTypeLengthsFormProps>();
  const lengthOptions = isEditDefault ? LENGTH_OPTIONS : LENGTH_OPTIONS_WITH_DEFAULT;
  const isOldAppointmentType = watch('lengthByPlan')?.length !== appointmentLengthsData?.length;
  const getDefaultLengthOption = (initialLength: string) => {
    return isEditDefault
      ? lengthOptions.find((item) => item.value === initialLength)
      : lengthOptions[0];
  };

  const setDefaultLength = (
    lengths: LengthByPlan[],
    index: number,
    field: 'initial' | 'subsequent'
  ) => {
    if (field === 'initial') {
      lengths[index].appointmentLengths.initial.isDefault = true;
    } else if (field === 'subsequent') {
      lengths[index].appointmentLengths.subsequent.isDefault = true;
    }
  };

  const onChangeLengths = (
    value: Option | OptionProps,
    field: 'initial' | 'subsequent',
    index: number
  ) => {
    if (appointmentLengthsData) {
      const lengths: LengthByPlan[] =
        watch('lengthByPlan')?.length && !isOldAppointmentType
          ? watch('lengthByPlan')
          : getDefaultLengthsData(
              appointmentLengthsData,
              isOldAppointmentType ? watch('lengthByPlan') : [],
              isEditDefault
            );

      const isDefault = lengths[index].appointmentLengths[field].isDefault;
      lengths[index].appointmentLengths[field].length = Number(value.value);

      if (isDefault) {
        const length = lengths[index].appointmentLengths[field].length;
        lengths[index].appointmentLengths[field].isDefault = !length;
      }

      if (value.value === '0') {
        setDefaultLength(lengths, index, field);
      }

      resetField('lengthByPlan', { defaultValue: lengths });
    }
  };

  useEffect(() => {
    if (appointmentLengthsData && !isFetching) {
      const lengths: LengthByPlan[] =
        watch('lengthByPlan')?.length && !isOldAppointmentType
          ? watch('lengthByPlan')
          : getDefaultLengthsData(
              appointmentLengthsData,
              isOldAppointmentType ? watch('lengthByPlan') : [],
              isEditDefault
            );

      const initial: LengthsItem[] = [];
      const subsequent: LengthsItem[] = [];

      appointmentLengthsData.forEach((length, index) => {
        const newInitialItem: LengthsItem = {
          planName: length.planName,
          label: length.appointmentLengths.initial.label.replace(/\s*\([^)]*\)/, ''),
          length: length.appointmentLengths.initial.length
        };

        const newSubsequentItem: LengthsItem = {
          planName: length.planName,
          label: length.appointmentLengths.subsequent.label.replace(/\s*\([^)]*\)/, ''),
          length: length.appointmentLengths.subsequent.length
        };

        initial.push(newInitialItem);
        subsequent.push(newSubsequentItem);

        resetField(`length.initial${index}`, {
          defaultValue: returnLengthOption(lengths, index, 'initial', isEditDefault),
          keepDirty: true
        });
        resetField(`length.subsequent${index}`, {
          defaultValue: returnLengthOption(lengths, index, 'subsequent', isEditDefault),
          keepDirty: true
        });
      });
      const isEditOldAppointmentType =
        watch('lengthByPlan')?.length &&
        watch('lengthByPlan').length !== appointmentLengthsData?.length;

      if ((!watch('lengthByPlan')?.length || isEditOldAppointmentType) && !isEditDefault) {
        resetField('lengthByPlan', { defaultValue: lengths });
      }
      setAppointmentLengths({ initial, subsequent });
    }
  }, [appointmentLengthsData, isEditDefault, isFetching, resetField, watch('lengthByPlan')]);

  const subLabelClasses =
    'text-gray-500 absolute top-4 font-semibold text-xs left-0 font-[Greycliff CF,Arial,Helvetica,sans-serif]';
  const controlledSelectLabelClasses = 'self-start';

  return (
    <div className="flex flex-col gap-4">
      <Loader isVisible={isLoading || isFetching} />
      {appointmentLengths.initial.map((initialLength, index) => (
        <div className="relative">
          <ControlledSelect
            labelDirection="row"
            defaultValue={getDefaultLengthOption(initialLength.length.toString())}
            key={`length.initial${index}`}
            control={control}
            name={`length.initial${index}`}
            options={lengthOptions}
            label={` ${initialLength.label} `}
            errors={formState.errors?.length?.[`initial${index}`]}
            labelClasses={controlledSelectLabelClasses}
            helper={formState.errors?.length?.[`initial${index}`]?.message?.toString()}
            onChange={(value) => {
              onChangeLengths(value, 'initial', index);
            }}
            rules={{
              validate: {
                required: (value) => handleRequired(value, `${initialLength.label} is required`)
              }
            }}
          />
          <p className={subLabelClasses}>({initialLength.planName})</p>
        </div>
      ))}

      {appointmentLengths.subsequent.map((subsequentLength, index) => (
        <div className="relative">
          <ControlledSelect
            key={`length.subsequent${index}`}
            control={control}
            labelDirection="row"
            defaultValue={getDefaultLengthOption(subsequentLength.length.toString())}
            options={lengthOptions}
            label={` ${subsequentLength.label} `}
            name={`length.subsequent${index}`}
            errors={formState.errors?.length?.[`subsequent${index}`]}
            helper={formState.errors?.length?.[`subsequent${index}`]?.message?.toString()}
            labelClasses={controlledSelectLabelClasses}
            onChange={(value) => {
              onChangeLengths(value, 'subsequent', index);
            }}
            rules={{
              validate: {
                required: (value) => handleRequired(value, `${subsequentLength.label} is required`)
              }
            }}
          />
          <p className={subLabelClasses}>({subsequentLength.planName})</p>
        </div>
      ))}
    </div>
  );
};

export default AppointmentTypeLengths;
