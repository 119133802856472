import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { CheckboxProps } from './checkbox.types';

const LabelCheckbox: React.FC<CheckboxProps> = ({
  checked,
  onCheckedChange,
  id,
  label,
  labelClassName
}) => {
  return (
    <div key={id} className="checkbox-container flex items-center gap-2">
      <RadixCheckbox.Root
        className={'flex size-4 items-center justify-center rounded border bg-white text-white'}
        checked={checked}
        onCheckedChange={onCheckedChange}
        id={id}
      >
        <RadixCheckbox.Indicator className="rounded border-secondary bg-secondary text-white">
          <Common.Icon className="size-4" name="check" />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <label
        className={classNames(labelClassName, `text-sm ${checked ? 'font-bold' : 'font-medium'}`)}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

export default LabelCheckbox;
