import { useEffect } from 'react';

import { Portal } from '@headlessui/react';
import Banner from 'components/common/Banner';
import useStartLocation from 'hooks/common/location/useStartLocation';
import { useLogout } from 'hooks/common/useLogout';
import { useMarkerio } from 'hooks/common/useMarkerio';
import { createBrowserRouter } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { ToastContainer } from 'react-toastify';
import { routerConfig } from 'router';

import { initialize } from './config/amplifyConfig';

const toastStyle = {
  width: '25%'
};

const router = createBrowserRouter(routerConfig);

function App() {
  const { logoutAllTabs } = useLogout();

  useEffect(() => {
    // Logout all tabs via the BroadcastChannel when user logs out
    logoutAllTabs();
  }, [logoutAllTabs]);

  useMarkerio();

  // Initialize Amplify only once
  useEffect(() => {
    initialize();
  }, []);

  useStartLocation();

  return (
    <>
      <Banner />
      <RouterProvider router={router} />
      <Portal>
        <ToastContainer position="bottom-right" theme="light" style={toastStyle} />
      </Portal>
    </>
  );
}

export default App;
