import { Common } from '@thecvlb/design-system';

import { ManageMessageDropdownProps } from './manageMessageDropdown.types';

const ManageMessageDropdown: React.FC<ManageMessageDropdownProps> = ({
  onDelete,
  onEdit,
  text,
  isFile = false
}) => (
  <div
    className={`absolute right-5 ${isFile ? 'mt-1 ' : 'mt-4'} z-20 -mr-6 w-40 rounded-lg border bg-white shadow-lg`}
  >
    <ul className="py-2">
      {text && (
        <li
          className="flex cursor-pointer items-center justify-between px-4 py-2 hover:bg-gray-100"
          onClick={onEdit}
        >
          <span>Edit</span>
          <Common.Icon name="pencil" className="size-5" />
        </li>
      )}
      <li
        className="flex cursor-pointer items-center justify-between px-4 py-2 text-red-500 hover:bg-gray-100"
        onClick={onDelete}
      >
        <span>Delete</span>
        <Common.Icon name="trash" className="size-5" />
      </li>
    </ul>
  </div>
);

export default ManageMessageDropdown;
